<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<DataTable ref="dt" :value="providers" v-model:selection="selectedProviders" selectionMode="multiple" dataKey="id" :paginator="true" :rows="20" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20, 35, 50]"
							currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} proveedor(es)" responsiveLayout="scroll"
							@change="onFilterChanged">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Administre proveedores</h5>
							<div id="table-header-inputs-container">
								<span id="table-search-input" class="block mt-2 md:mt-0 p-input-icon-left">
									<i class="pi pi-search" />
									<InputText v-model="filters['global'].value" placeholder="Buscar..." />
								</span>
								<span id="table-buttons-container" class="block mt-2 md:mt-0">
									<Button icon="pi pi-plus" class="p-button-rounded p-button-success mr-2" @click="openNew" />
									<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="confirmDeleteSelected" :disabled="!selectedProviders || !selectedProviders.length" />
									<!-- <Button icon="pi pi-download" class="p-button-rounded p-button-help mr-2" :loading="loadingDownloadButton" @click="downloadProviders" /> -->
								</span>
							</div>
						</div>
					</template>

                    <Column field="companyIdentification" header="Céd./RUC" :sortable="true" headerStyle="width:22.5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Céd./RUC</span>
							{{slotProps.data.companyIdentification || "-"}}
						</template>
					</Column>
					<Column field="company" header="Razón social" :sortable="true" headerStyle="width:22.5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Razón social</span>
							{{slotProps.data.company}}
						</template>
					</Column>
					<Column field="phoneNumber" header="Teléfono" :sortable="true" headerStyle="width:22.5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Teléfono</span>
							{{slotProps.data.phoneNumber || "-"}}
						</template>
					</Column>
                    <Column field="email" header="Email" :sortable="true" headerStyle="width:22.5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Email</span>
							{{slotProps.data.email || "-"}}
						</template>
					</Column>
                    <Column headerStyle="width:10%; min-width:15rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProvider(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2" @click="confirmDeleteProvider(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="providerDialog" :style="{width: '450px'}" header="Proveedor" :modal="true" class="p-fluid">
					<div class="field">
						<label for="company">Razón social</label>
						<InputText id="company" v-model.trim="this.provider.company" required="true" autofocus :class="{'p-invalid': submitted && !this.provider.company}" />
						<small class="p-invalid" v-if="submitted && !this.provider.company">El nombre es obligatorio.</small>
					</div>
                    <div class="field">
						<label for="companyIdentification">Céd./RUC</label>
						<InputText id="companyIdentification" v-model.trim="this.provider.companyIdentification" required="true" autofocus :class="{'p-invalid': submitted && !this.provider.companyIdentification}" />
						<small class="p-invalid" v-if="submitted && !this.provider.companyIdentification">Céd./RUC es obligatorio.</small>
					</div>
                    <div class="field">
						<label for="representative">Nombre de Representante</label>
						<InputText id="representative" v-model.trim="this.provider.representative" autofocus />
					</div>
                    <div class="field">
						<label for="phoneNumber">Teléfono</label>
						<InputText id="phoneNumber" v-model.trim="this.provider.phoneNumber" autofocus />
					</div>
                    <div class="field">
						<label for="email">Email 1</label>
						<InputText id="email" v-model.trim="this.provider.email" autofocus />
					</div>
                    <div class="field">
						<label for="email2">Email 2</label>
						<InputText id="email2" v-model.trim="this.provider.email2" autofocus />
                    </div>
                    <!-- <div class="field">
						<label for="rentRetentionPercentage">Porcentaje retencion Renta</label>
						<InputText id="rentRetentionPercentage" v-model.trim="this.provider.rentRetentionPercentage" autofocus />
                    </div>
                    <div class="field">
						<label for="ivaRetentionPercentage">Porcentaje retencion IVA</label>
						<InputText id="ivaRetentionPercentage" v-model.trim="this.provider.ivaRetentionPercentage" autofocus />
                    </div> -->
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-success mr-2 mb-2" @click="saveProvider" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProviderDialog" :style="{width: '450px'}" header="Confirmación Requerida" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="this.provider">¿Está seguro de querer borrar <b>{{this.provider.company}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="deleteProviderDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-warning mr-2 mb-2" @click="deleteProvider" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProvidersDialog" :style="{width: '450px'}" header="Confirmación Requerida" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="this.selectedProviders">¿Está seguro de querer borrar los proveedores seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="deleteProvidersDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-warning mr-2 mb-2" @click="deleteSelectedProviders" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import download from 'downloadjs';
import {FilterMatchMode} from 'primevue/api';
import httpRequest from '../../service/httpRequest';
import Product from '../../models/product';
import ProductProvider from '../../models/productProvider';

export default {
	data() {
		return {
			providers: [],
			providerDialog: false,
			deleteProviderDialog: false,
			deleteProvidersDialog: false,
			provider: {},
			selectedProviders: null,
			filters: {},
			submitted: false,
			loadingDownloadButton: false,
		}
	},
	created() {
        this.initFilters();
        this.getProviders();
	},
	mounted() {
    },
	methods: {
		async onFilterChanged(event) {
			let filter = event.srcElement.value;
            if (filter.length > 1) {
                const result = await httpRequest.getProviders(filter);
                if (result.status === 200) {
                    this.products = result.data.map(product => new Product(product));
                } else { this.products = [] }
            }
		},
        openNew() {
			this.provider = new ProductProvider();
			this.submitted = false;
			this.providerDialog = true;
		},
		hideDialog() {
			this.selectedProviders = null;
			this.providerDialog = false;
			this.submitted = false;
		},
		async saveProvider() {
			this.selectedProviders = null;
            let response = await httpRequest.saveProvider(this.provider);
            if (response.status == 200) {
                let provider = new ProductProvider(response.data);
                this.addProviderToList(provider);
                this.provider = null;
            } else { console.log("Error: ", response.status); }
            this.hideDialog();
		},
        async getProviders() {
            let response = await httpRequest.getProviders(false);
            if (response.status == 200) {
                this.providers = response.data.map(provider => { return new ProductProvider(provider) })
            } else { console.log("Error: ", response.status); }
        },
		async editProvider(provider) {
			this.provider = {...provider};
			this.providerDialog = true;
		},
		confirmDeleteProvider(provider) {
			this.selectedProviders = null;
			this.provider = provider;
			this.deleteProviderDialog = true;
		},
		async deleteProvider() {
			this.selectedProviders = null;
            let response = await httpRequest.deleteProviders([this.provider.id]);
            if (response.status) { 
                this.deleteProviderFromList(this.provider);
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Proveedor eliminado', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Hemos tenido un problema borrando el proveedor', life: 3000});
            }
			this.deleteProviderDialog = false;
		},
		async downloadProducts() {
			this.loadingDownloadButton = true;
			const data = await httpRequest.downloadProducts();
			download(data, "productos" + ".xlsx", "application/vnd.ms-excel");
			this.loadingDownloadButton = false;
		},
		confirmDeleteSelected() {
			this.deleteProvidersDialog = true;
		},
		async deleteSelectedProviders() {
            let ids = this.selectedProviders.map(provider => provider.id);
            let response = await httpRequest.deleteProviders(ids);
            if (response.status) {
                for (const index in this.selectedProviders) { this.deleteProviderFromList(this.selectedProviders[index]) }
                this.selectedProviders = null;
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Proveedor(es) eliminado', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Hemos tenido un problema borrando los proveedores seleccionados', life: 3000});
            }
			this.deleteProvidersDialog = false;
        },
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        providerIndex(provider) {
            var index = -1;
            if (provider.id === null) { index = -1; return index; }
            for (var i=0; i<this.providers.length; i++) {
                if (this.providers[i].id === provider.id) { return i; }
            }
            return index;
        },
        addProviderToList(provider) {
            let index = this.providerIndex(provider);
            if (index > -1) { this.providers[index] = provider }
            else { this.providers = [provider, ...this.providers] } 
        },
        deleteProviderFromList(provider) {
            let index = this.providerIndex(provider);
            this.providers.splice(index, 1);
            this.provider = null;
            if (this.providers.length == 0) { this.providers = [] }
        },
	}
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';

@media screen and (max-width: 769px) {
	#table-header-inputs-container {
		display: block;
	}
	#table-search-input {
		margin: 15px 0px !important;
	}
}
@media screen and (min-width: 769px) {
	#table-header-inputs-container {
		display: flex;
	}
	#table-search-input {
		margin: 0px 15px;
	}
}
</style>
